import {makeAutoObservable} from "mobx";

class CounterStore {
    count = 0;

    constructor() {
        makeAutoObservable(this); // Makes `count` observable and automatically binds methods
    }

    increment() {
        this.count++;
    }

    decrement() {
        this.count--;
    }

    addFive() {
        this.count = this.count + 5;
    }
}

const counterStore = new CounterStore();
export default counterStore;