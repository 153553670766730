import React from "react";
import {observer} from "mobx-react-lite";
import counterStore from "../store/CounterStore";

const Counter2 = observer(() => {
    return (
        <div>
            <h1>Count: {counterStore.count}</h1>
            <button className="btn btn-primary" onClick={() => counterStore.increment()}>Increment ++</button>
            <button className="btn btn-secondary"  onClick={() => counterStore.decrement()}>Decrement --</button>
            <button className="btn btn-accent" onClick={() => counterStore.addFive()}>Add 5</button>
        </div>
    );
});

export default Counter2;