import React from "react";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
const TestButton =  () => {
    return (
        <div>
            <h1>Welcome to My Symfony + React App</h1>
            <Button variant="contained" startIcon={<SendIcon/>} color="primary" disableElevation>
                Click Me
            </Button>
        </div>
    );
};

export default TestButton;