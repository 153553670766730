import React, {useEffect, useState} from "react";
import ButtonUsage from "./Button";
import AbcIcon from '@mui/icons-material/Abc';

// or
function Fetch() {
    const [statusData, setStatusData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch data from the API
        fetch('https://status.digitalocean.com/api/v2/summary.json')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setStatusData(data);
                setTimeout(function(){
                    setLoading(false);

                }, 1000);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, []); // Empty dependency array means this runs once when component mounts

    if (loading) return <div className=""><strong>Loading...</strong><br/><img src="/assets/img/loading.gif" alt="Logo" />
    </div>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <ButtonUsage/>
            <AbcIcon/>
            <h2>DigitalOcean Status Summary</h2>
            <p>Status: {statusData?.status?.description}</p>
            <h3>Components</h3>
            <ul>
                {statusData?.components?.map((component) => (
                    <li key={component.id}>
                        <strong>{component.name}:</strong> {component.status}
                    </li>
                ))}
            </ul>
        </div>
    );
}
export default Fetch;