import {createRoot} from 'react-dom/client';
import './styles/app.css';
import {Editor} from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import React from "react";
import Fetch from "./js/components/fetch";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import DataTable from "./js/components/Datatable";
import Login from './js/components/Login';
import Counter2 from './js/components/Counter2';
import TestButton from './js/components/TestButton';


const container = document.getElementById('root');
const root = createRoot(container);


document.addEventListener('DOMContentLoaded', function () {

    const components = {
        //'hello-world': HelloWorld,
        'datatable': DataTable,
        'fetch': Fetch,
        'login-root': Login,
        'counter2': Counter2,
        'testbutton': TestButton
        //'counter': Counter,
    };

    function renderComponents(componentMap) {
        Object.entries(componentMap).forEach(([elementId, { Component, props = {} }]) => {
            const container = document.getElementById(elementId);
            if (container) {
                const root = createRoot(container);
                root.render(<Component {...props} />);
            }
        });
    }

// Define the components and their props
    const componentMap = {
        //'hello-world': { Component: HelloWorld, props: { name: "Symfony User" } },
        'datatable': { Component: DataTable },
        'fetch': { Component: Fetch },
        'login-root': { Component: Login },
        'counter2': { Component: Counter2 },
        'testbutton': { Component: TestButton },
        //'counter': { Component: Counter },
    };

// Render all components
    renderComponents(componentMap);
});

document.querySelectorAll('.tiptap-editor').forEach((element) => {
    const editor = new Editor({
        element,
        extensions: [
            StarterKit,
            Placeholder.configure({
                placeholder: 'Start typing...',
            }),
        ],
        content: element.value, // Use existing content if any
        onUpdate: ({ editor }) => {
            element.value = editor.getHTML(); // Sync the content with the form input
        },
    });
});