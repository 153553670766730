import React, {useState} from 'react';
import axios from 'axios';

const Login = () => {
    // Extract data attributes from the root element
    const rootElement = document.getElementById('login-root');
    const csrfToken = rootElement?.getAttribute('data-csrf-token') || '';
    const lastUsername = rootElement?.getAttribute('data-last-username') || '';

    // Local state for form fields and errors
    const [username, setUsername] = useState(lastUsername);
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('_csrf_token', csrfToken);
        formData.append('_username', username);
        formData.append('_password', password);

        try {
            const response = await axios.post('/login', formData);
            if (response.status === 200) {
                window.location.href = '/admin'; // Redirect on success
            }
        } catch (err) {
            setError('Invalid credentials. Please try again.');
        }
    };

    return (
        <div className="login-form ">
            <h2>Login</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit} className="rounded px-8 pt-6 pb-8 mb-4 w-full">
                <div className="mb-4">
                    <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">Username</label>
                    <input
                        type="text"
                        id="_username"
                        name="_username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password</label>
                    <input
                        type="password"
                        id="_password"
                        name="_password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                    />
                </div>
                <input type="hidden" name="_csrf_token" value={csrfToken}/>
                <div className="flex justify-center">
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/2">Login</button>
                </div>
            </form>
        </div>
);
};

export default Login;